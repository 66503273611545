import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Store} from "@ngrx/store";
import {AppState} from "../../core/store/reducers";
import {ErrorActions, UserActions} from "../../core/store/actions";
import {UserModel} from "../../models/interfaces/user-model";
import {ANNOUNCEMENT_URL, DICTIONARY_URL, USER_INFO_URL} from "../../models/const/api";
import {DictionaryModel} from "../../models/interfaces/dictionary-model";
import {AnnouncementModelApi} from "../../models/interfaces/notification-model";

@Injectable({providedIn: 'root'})
export class AuthService implements OnDestroy {
    private readonly tokenBS = new BehaviorSubject<string | null>(null);

    private readonly token$ = this.tokenBS.asObservable();

    private tokenSubscription = this.token$.subscribe((token) => {
        if (!token?.length) {
            return this.notifyUserLoginError();
        }

        this.initUser(token);
    });

    constructor(
        private readonly cookieService: CookieService,
        private readonly httpClient: HttpClient,
        private readonly store: Store<AppState>,
    ) {
    }

    init(): void {
        const authToken = this.cookieService.get(environment.cookieName);
        this.tokenBS.next(authToken);
        this.setAuthCookieWatcher();
    }

    initUser(token: string): void {
        this.store.dispatch(UserActions.initUser({token}));
    }

    setAuthCookieWatcher(): void {
        setInterval(() => { /** @todo find more elegant solution */
            if (this.cookieService.get(environment.cookieName) !== this.tokenBS.getValue()) {
                const newToken = this.cookieService.get(environment.cookieName);
                this.tokenBS.next(newToken);
            }
        }, 1500);
    }

    getUserData(): Observable<UserModel> {
        return this.httpClient.get<UserModel>(USER_INFO_URL, {
            withCredentials: true,
        })
    }

    getUserAnnouncement(): Observable<AnnouncementModelApi> {
        return this.httpClient.get<AnnouncementModelApi>(ANNOUNCEMENT_URL, {
            withCredentials: true,
        })
    }

    getDictionary(): Observable<DictionaryModel> {
        return this.httpClient.get<DictionaryModel>(DICTIONARY_URL, {
            withCredentials: true,
        })
    }

    notifyUserAuthExpired(): void {
        this.store.dispatch(ErrorActions.userAuthenticationExpiredError());
    }

    notifyUserLoginError(): void {
        this.store.dispatch(ErrorActions.userAuthenticationError());
    }

    ngOnDestroy(): void {
        this.tokenSubscription.unsubscribe();
    }
}
